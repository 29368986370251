import React, { useState, useEffect } from 'react';
import './HeadAdmin.css';
import '../../Styles.css';
import { Form } from 'react-bootstrap';
import { IClub } from '../../interfaces/IClub';
import AddClubHeadAdminModal from '../../Modals/AddClubHeadAdminModal/AddClubHeadAdminModal';
import { IUser } from '../../interfaces/IUser';
import { IChat } from '../../interfaces/IChat';
import AddClubAdminModal from '../../Modals/AddClubAdminModal/AddClubAdminModal';
import { useNavigate } from 'react-router-dom';
import AddHeadAdminModal from '../../Modals/AddHeadAdminModal/AddHeadAdminModal';
import AddClubToUserModal from '../../Modals/AddClubToUserModal/AddClubToUserModal';
import RemoveClubFromUserModal from '../../Modals/RemoveClubFromUserModal/RemoveClubFromUserModal';
import AddChatModal from '../../Modals/AddChatModal/AddChatModal';

const HeadAdmin: React.FC = () => {
    const [clubs, setClubs] = useState<IClub[]>([]);
    const [newClubName, setNewClubName] = useState('');
    const [selectedClubId, setSelectedClubId] = useState<number | ''>('');
    const [showAddClubHeadAdminModal, setShowAddClubHeadAdminModal] = useState(false);
    const [showAddClubAdminModal, setShowAddClubAdminModal] = useState(false);
    const [showAddHeadAdminModal, setShowAddHeadAdminModal] = useState(false);
    const [selectedClubHeadAdminId, setSelectedClubHeadAdminId] = useState<number | ''>('');
    const [selectedClubAdminId, setSelectedClubAdminId] = useState<number | ''>('');
    const [selectedHeadAdminId, setSelectedHeadAdminId] = useState<number | ''>('');
    const [clubHeadAdmins, setClubHeadAdmins] = useState<IUser[]>([]);
    const [clubAdmins, setClubAdmins] = useState<IUser[]>([]);
    const [headAdmins, setHeadAdmins] = useState<IUser[]>([]);
    const [addTgChatId, setAddTgChatId] = useState('');
    const [showAddClubToUserModal, setShowAddClubToUserModal] = useState(false);
    const [showRemoveClubFromUserModal, setShowRemoveClubFromUserModal] = useState(false);
    const [showAddChatModal, setShowAddChatModal] = useState(false);
    const [chatsId, setChatsId] = useState<IChat[]>([]);
    const [deleteTgChatId, setDeleteTgChatId] = useState<string>('');
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchClubs();
        fetchClubHeadAdmins();
        fetchClubAdmins();
        fetchChats();
        fetchHeadAdmins();
    }, []);
    
    const fetchChats = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tgChats/getAll`);
            const data: IChat[] = await response.json();
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('Fetched chats:', data);
            }
            setChatsId(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при получении чатов:', error);
            }
        }
    };
    
    const fetchHeadAdmins = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-headAdmins`);
            const data = await response.json();
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('Head Admins:', data);
            }
            setHeadAdmins(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при получении владельцев клубов:', error);
            }
        }
    };
    
    const handleAddChat = async (chatId: string, clubId: number, userName: string) => {
        try {
            const selectedClub = clubs.find(club => club.id === clubId);
            const clubName = selectedClub ? selectedClub.name : '';
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tgChats/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tg_id: chatId, club_id: clubId, club_name: clubName, user_name: userName }),
            });
            
            if (response.ok) {
                fetchChats();
                alert('ID чата успешно добавлен.');
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении ID чата.');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении ID чата:', error);
            }
        }
    };
    
    const handleDeleteTgChatIdSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDeleteTgChatId(event.target.value);
    };
    
    const handleAddClubToUser = async (userId: number, clubId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/addClub-ToUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, clubId }),
            });
            
            if (response.ok) {
                setShowAddClubToUserModal(false);
                alert('Клуб успешно добавлен пользователю.');
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении клуба пользователю');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении клуба пользователю:', error);
            }
        }
    };
    
    const handleRemoveClubFromUser = async (userId: number, clubId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/removeClub-ToUser`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, clubId }),
            });
            
            if (response.ok) {
                setShowRemoveClubFromUserModal(false);
                alert('Клуб успешно удален у пользователя.');
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении клуба у пользователя');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при удалении клуба у пользователя:', error);
            }
        }
    };
    
    const handleSubmitDeleteTgChatId = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if (!deleteTgChatId) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('No chat ID selected');
            }
            return;
        }
        
        const selectedChat = chatsId.find(chat => chat.tg_id.toString() === deleteTgChatId);
        
        if (!selectedChat) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('Selected chat not found');
            }
            return;
        }
        
        if (process.env.DEBAG_MOD === 'DEBAG') { 
            console.log('Selected chat:', selectedChat);
        }
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tgChats/remove`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tg_id: selectedChat.tg_id,
                    user_name: selectedChat.user_name,
                    club_name: selectedChat.club_name,
                }),
            });
            
            if (response.ok) {
                if (process.env.DEBAG_MOD === 'DEBAG') { 
                    console.log('Chat successfully deleted');
                }
                fetchChats();
            } else {
                const errorData = await response.json();
                if (process.env.DEBAG_MOD === 'DEBAG') { 
                    console.error('Error deleting chat:', errorData.message);
                }
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Error deleting chat:', error);
            }
        }
    };
    
    
    
    
    const fetchClubs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/club/getAll`);
            if (!response.ok) {
                throw new Error('Ошибка при получении списка клубов');
            }
            const data = await response.json();
            setClubs(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при получении списка клубов:', error);
            }
        }
    };
    
    useEffect(() => {
        fetchClubs();
    }, []);
    
    const fetchClubHeadAdmins = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-headClubAdmins`);
            const data: IUser[] = await response.json();
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('Club Head Admins:', data);
            }
            data.forEach((admin: IUser) => {
                if (process.env.DEBAG_MOD === 'DEBAG') { 
                    console.log('Admin:', admin);
                    console.log('Admin Clubs:', admin.clubs);
                }
            });
            setClubHeadAdmins(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при получении списка руководителей клубов:', error);
            }
        }
    };
    
    const fetchClubAdmins = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-clubAdmins`);
            if (response.ok) {
                const data: IUser[] = await response.json();
                if (process.env.DEBAG_MOD === 'DEBAG') { 
                    console.log('Club Admins:', data);
                }
                setClubAdmins(Array.isArray(data) ? data : []);
            } else {
                if (process.env.DEBAG_MOD === 'DEBAG') { 
                    console.error('Ошибка при получении списка администраторов клубов:', await response.text());
                }
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при получении списка администраторов клубов:', error);
            }
        }
    };
    
    const handleAddClub = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            if (!newClubName.trim()) {
                alert('Пожалуйста, введите название клуба.');
                return;
            }
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/club/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newClubName }),
            });
            if (response.ok) {
                setNewClubName('');
                fetchClubs();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении клуба:', error);
            }
        }
    }; 
    
    const handleDeleteClub = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedClubId === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/club/delete/${selectedClubId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setSelectedClubId('');
                fetchClubs();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при удалении клуба:', error);
            }
        }
    };
    
    const handleAddClubHeadAdmin = async (name: string, login: string, password: string, clubId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/add-headClubAdmin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, login, password, clubId }),
            });
            if (response.ok) {
                setShowAddClubHeadAdminModal(false);
                fetchClubs();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении руководителя клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении руководителя клуба:', error);
            }
        }
    };
    
    const handleAddHeadAdmin = async (name: string, login: string, password: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/add-headAdmin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, login, password }),
            });
            if (response.ok) {
                setShowAddClubHeadAdminModal(false);
                fetchClubs();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении руководителя клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении руководителя клуба:', error);
            }
        }
    };
    
    const handleDeleteClubHeadAdmin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedClubHeadAdminId === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete-headClubAdmin/${selectedClubHeadAdminId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setSelectedClubHeadAdminId('');
                fetchClubHeadAdmins();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении руководителя клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при удалении руководителя клуба:', error);
            }
        }
    };
    
    const handleDeleteHeadAdmin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedClubHeadAdminId === '') return;
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete-headAdmin/${selectedClubHeadAdminId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setSelectedClubHeadAdminId('');
                fetchClubHeadAdmins();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении руководителя клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при удалении руководителя клуба:', error);
            }
        }
    };
    
    const handleAddClubAdmin = async (name: string, login: string, password: string, clubId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/add-clubAdmin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, login, password, clubId }),
            });
            
            if (response.ok) {
                setShowAddClubAdminModal(false);
                fetchClubAdmins();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении администратора клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при добавлении администратора клуба:', error);
            }
        }
    };
    
    const handleDeleteClubAdmin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedClubAdminId === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete-clubAdmin/${selectedClubAdminId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setSelectedClubAdminId('');
                fetchClubAdmins();
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении администратора клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при удалении администратора клуба:', error);
            }
        }
    };
    
    return (
        <div className='headAdmin_container'>
            <div className='headAdmin_inputs'>
                <Form noValidate className='formContainer' onSubmit={handleAddClub}>
                    <Form.Group className='mb-3'>
                        <Form.Label>Добавить клуб</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите название клуба"
                            value={newClubName}
                            onChange={(e) => setNewClubName(e.target.value)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Введите название клуба
                        </Form.Control.Feedback>
                    </Form.Group>
                    <button type="submit" className="modal_button">Добавить клуб</button>
                </Form>
                
                <Form noValidate className='formContainer' onSubmit={handleDeleteClub}>
                    <Form.Group className="mb-3" controlId="clubDelete">
                        <Form.Label>Удалить клуб</Form.Label>
                        <Form.Control as="select" value={selectedClubId} onChange={(e) => setSelectedClubId(parseInt(e.target.value))}>
                            <option value="">Выберите клуб</option>
                            {clubs.map((club) => (
                                <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить клуб</button>
                </Form>
                
                <hr />
                
                <button className="modal_button" onClick={() => setShowAddClubHeadAdminModal(true)}>
                    Добавить руководителя компьютерного клуба
                </button>
                
                <AddClubHeadAdminModal
                    show={showAddClubHeadAdminModal}
                    onHide={() => setShowAddClubHeadAdminModal(false)}
                    onAddClubHeadAdmin={handleAddClubHeadAdmin}
                    clubs={clubs}
                />
                
                <Form noValidate className='formContainer' onSubmit={handleDeleteClubHeadAdmin}>
                    <Form.Group className="mb-3" controlId="headClubAdminDelete">
                        <Form.Label>Удалить руководителя компьютерного клуба</Form.Label>
                        <Form.Control as="select" value={selectedClubHeadAdminId} onChange={(e) => setSelectedClubHeadAdminId(parseInt(e.target.value))}>
                            <option value="">Выберите руководителя</option>
                            {Array.isArray(clubHeadAdmins) && clubHeadAdmins.map((admin) => {
                                const clubName = admin.clubs && admin.clubs.length > 0 ? admin.clubs[0].name : 'Без клуба';
                                return (
                                    <option key={admin.id} value={admin.id}>{admin.name} - {clubName}</option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить руководителя компьютерного клуба</button>
                </Form>
                
                <hr />
                
                <button className="modal_button" onClick={() => setShowAddClubAdminModal(true)}>
                    Добавить администратора компьютерного клуба
                </button>
                
                <AddClubAdminModal
                    show={showAddClubAdminModal}
                    onHide={() => setShowAddClubAdminModal(false)}
                    onAddClubHeadAdmin={handleAddClubAdmin}
                    clubs={clubs}
                />
                
                <Form noValidate className='formContainer' onSubmit={handleDeleteClubAdmin}>
                    <Form.Group className="mb-3" controlId="adminDelete">
                        <Form.Label>Удалить администратора компьютерного клуба</Form.Label>
                        <Form.Control as="select" value={selectedClubAdminId} onChange={(e) => setSelectedClubAdminId(parseInt(e.target.value))}>
                            <option value="">Выберите администратора</option>
                            {clubAdmins.map((admin) => {
                                const clubName = admin.clubs && admin.clubs.length > 0 ? admin.clubs[0].name : 'Без клуба';
                                return (
                                    <option key={admin.id} value={admin.id}>{admin.name} - {clubName}</option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить администратора компьютерного клуба</button>
                </Form>
                
                <hr />
                
                <button className="modal_button" onClick={() => setShowAddHeadAdminModal(true)}>
                    Добавить владельца компьютерного клуба
                </button>
                
                <AddHeadAdminModal
                    show={showAddHeadAdminModal}
                    onHide={() => setShowAddHeadAdminModal(false)}
                    onAddClubHeadAdmin={handleAddHeadAdmin}
                    clubs={clubs}
                />
                
                <Form noValidate className='formContainer' onSubmit={handleDeleteHeadAdmin}>
                    <Form.Group className="mb-3" controlId="headAdminDelete">
                        <Form.Label>Удалить владельца компьютерного клуба</Form.Label>
                        <Form.Control as="select" value={selectedHeadAdminId} onChange={(e) => setSelectedHeadAdminId(parseInt(e.target.value))}>
                            <option value="">Выберите владельца</option>
                            {Array.isArray(headAdmins) && headAdmins.map((admin) => {
                                const clubName = admin.clubs && admin.clubs.length > 0 ? admin.clubs[0].name : 'Без клуба';
                                return (
                                    <option key={admin.id} value={admin.id}>{admin.name} - {clubName}</option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить владельца компьютерного клуба</button>
                </Form>
                
                <hr />
                
                <button className="modal_button" onClick={() => setShowAddClubToUserModal(true)}>
                    Добавить сотрудника в клуб
                </button>
                
                <AddClubToUserModal
                    show={showAddClubToUserModal}
                    onHide={() => setShowAddClubToUserModal(false)}
                    onAddClubToUser={handleAddClubToUser}
                />
                
                <button className="modal_button" onClick={() => setShowRemoveClubFromUserModal(true)}>
                    Удалить сотрудника из клуба
                </button>
                
                <RemoveClubFromUserModal
                    show={showRemoveClubFromUserModal}
                    onHide={() => setShowRemoveClubFromUserModal(false)}
                    onRemoveClubFromUser={handleRemoveClubFromUser}
                />
                
                <hr />
                
                <button className="modal_button" onClick={() => setShowAddChatModal(true)}>
                    Добавить телеграмм чат
                </button>
                
                <AddChatModal
                    show={showAddChatModal}
                    onHide={() => setShowAddChatModal(false)}
                    onAddChat={handleAddChat}
                    clubs={clubs}
                />
                
                <Form noValidate onSubmit={handleSubmitDeleteTgChatId} className='formContainer'>
                    <Form.Group className="mb-3" controlId="tgChatDelete">
                        <Form.Label>Удалить телеграмм чат</Form.Label>
                        <select
                            className="form-control"
                            value={deleteTgChatId}
                            onChange={handleDeleteTgChatIdSelectChange}
                        >
                            <option value="">Выберите чат</option>
                            {chatsId.map(chat => (
                                <option key={`${chat.tg_id}-${chat.club_id}-${chat.user_name}`} value={chat.tg_id.toString()}>
                                    {`${chat.tg_id} | ${chat.user_name} | ${chat.club_name}`}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить телеграмм чат</button>
                </Form>
                
                <button className="onGenegateReport_button" onClick={() => navigate('/SubmitReport')}>
                    Страница сдачи отчета
                </button>
                
                <button className="onGenegateReport_button" onClick={() => navigate('/GenerateReport')}>
                    Страница формирования отчета
                </button>
            </div>
        </div>
    );
};

export default HeadAdmin;
