import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import '../../Styles.css';
import './RegisterFirstUser.css';

const RegisterFirstUser: React.FC = () => {
    const [alert, setAlert] = useState<{ type: string; message: string } | null>(null);
    const [formData, setFormData] = useState({
        name: '',
        login: '',
        password: '',
        registerPassword: '',
        roleName: 'head_admin'
    });
    
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            // Проверка пароля регистрации
            const passwordCheckResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-password/verify`, {
                password: formData.registerPassword,
            });
            
            if (!passwordCheckResponse.data.success) {
                throw new Error(passwordCheckResponse.data.message || 'Ошибка проверки пароля');
            }
            
            // Регистрация пользователя
            await axios.post(`${process.env.REACT_APP_API_URL}/api/user/registerFirstUser`, formData);
            setAlert({ type: 'success', message: 'Первый пользователь успешно зарегистрирован' });
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при регистрации первого пользователя:', error);
            }
            if (axios.isAxiosError(error) && error.response) {
                setAlert({ type: 'danger', message: error.response.data.message || 'Ошибка при регистрации первого пользователя' });
            } else if (error instanceof Error) {
                setAlert({ type: 'danger', message: error.message });
            } else {
                setAlert({ type: 'danger', message: 'Произошла неизвестная ошибка' });
            }
        }
    };
    
    return (
        <div className="regFirstUser_container">
            {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
            <Form onSubmit={handleSubmit} className="formContainer">
                <Form.Group controlId="formName">
                    <Form.Control
                        type="text"
                        placeholder="Введите имя"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
                
                <Form.Group controlId="formLogin">
                    <Form.Control
                        type="text"
                        placeholder="Введите логин"
                        name="login"
                        value={formData.login}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
                
                <Form.Group controlId="formPassword">
                    <Form.Control
                        type="password"
                        placeholder="Введите пароль"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
                
                <Form.Group controlId="formRegisterPassword">
                    <Form.Control
                        type="password"
                        placeholder="Введите пароль для регистрации"
                        name="registerPassword"
                        value={formData.registerPassword}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>
                
                <button className='modal_button' type="submit">
                    Зарегистрировать
                </button>
            </Form>
        </div>
    );
};

export default RegisterFirstUser;