import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import '../ModalStyles.css';

interface AddHeadAdminModalProps {
    show: boolean;
    onHide: () => void;
    onAddClubHeadAdmin: (name: string, login: string, password: string ) => void;
    clubs: { id: number, name: string }[];
}

const AddHeadAdminModal: React.FC<AddHeadAdminModalProps> = ({ show, onHide, onAddClubHeadAdmin }) => {
    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onAddClubHeadAdmin(name, login, password);
        setName('');
        setLogin('');
        setPassword('');
    };
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Form className='modal_formContainer' onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </Form.Group>
                    
                    <Form.Group controlId="formLogin">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control type="text" value={login} onChange={(e) => setLogin(e.target.value)} required />
                    </Form.Group>
                    
                    <Form.Group controlId="formPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </Form.Group>
                    
                    <button type="submit" className="modal_button">
                        Добавить
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddHeadAdminModal;