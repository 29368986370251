import React, { useState } from 'react';
import './Main.css';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useAuth } from '../../components/AuthContext/AuthContext';

const Main: React.FC = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { setUser } = useAuth();
    
    const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(event.target.value);
    };
    
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ login, password }),
            });
            
            const data = await response.json();
            
            if (response.ok) {
                setUser(data);
                
                if (data.role === 1) {
                    navigate('/HeadAdmin');
                } else if (data.role === 2) {
                    navigate('/HeadClubAdmin');
                } else if (data.role === 3) {
                    navigate('/SubmitReport');
                } else {
                    navigate('/');
                }
            } else {
                alert(data.message || 'Ошибка при входе');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при отправке запроса:', error);
                alert('Произошла ошибка при входе');
            }
        }
    };
    
    return (
        <div className='main_container'>
            <Form noValidate className='main_loginFormContainer' onSubmit={handleSubmit}>
                <Form.Group className='main_loginFormGroup'>
                    <Form.Label>ВОЙТИ</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Логин"
                        value={login}
                        onChange={handleLoginChange}
                        autoComplete="username"
                    />
                    <Form.Control
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={handlePasswordChange}
                        autoComplete="current-password"
                    />
                </Form.Group>
                <button type="submit" className="modal_button">Войти</button>
            </Form>
        </div>
    );
};

export default Main;