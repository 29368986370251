const formatDate = (date: Date): string => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    
    const dayStr = day < 10 ? '0' + day.toString() : day.toString();
    const monthStr = month < 10 ? '0' + month.toString() : month.toString();
    
    return `${dayStr}.${monthStr}.${year}`;
}

export default formatDate;