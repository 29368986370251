import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SubmitReport.css';
import '../../Styles.css'
import { Form, Spinner } from 'react-bootstrap';
import { useAuth } from '../../components/AuthContext/AuthContext';

const SubmitReport: React.FC = () => {
    const [alert, setAlert] = useState<{ type: string; message: string } | null>(null);
    const [loading, setLoading] = useState(false);
    const [initialTotalCheckout, setInitialTotalCheckout] = useState<number>(0);
    const [clubs, setClubs] = useState<{ id: string; name: string }[]>([]);
    const { user } = useAuth();
    const [reportData, setReportData] = useState<{
        admin: string;
        date: string;
        image_cheque: File | null;
        computers_cash: string;
        computers_non_cash: string;
        computers_cloud_payments: string;
        ps_cash: string;
        ps_non_cash: string;
        bar_cash: string;
        bar_non_cash: string;
        sbp: string;
        non_cash: string;
        cash: string;
        total: string;
        total_checkout: string;
        bonuses: string;
        total_cork_fee: string;
        cork_fee_cash: string;
        cork_fee_non_cash: string;
        day_or_night: string;
        comment: string;
        cloud_payments: string;
        encashment: string;
        total_ps: string;
        total_computers: string;
        total_bar: string;
        online_payments: string;
        start_checkout: string;
        club_id: string;
        user_id: string;
        club_name: string; // добавлено club_name
    }>({
        admin: user?.name || '',
        date: '',
        bonuses: '',
        total_cork_fee: '',
        cork_fee_cash: '',
        cork_fee_non_cash: '',
        image_cheque: null,
        computers_cash: '',
        computers_non_cash: '',
        computers_cloud_payments: '',
        ps_cash: '',
        ps_non_cash: '',
        bar_cash: '',
        bar_non_cash: '',
        sbp: '',
        non_cash: '',
        cash: '',
        total: '',
        total_checkout: '',
        day_or_night: '',
        comment: '',
        cloud_payments: '',
        encashment: '',
        total_ps: '',
        total_computers: '',
        total_bar: '',
        online_payments: '',
        start_checkout: '',
        club_id: user?.club_id?.toString() || '',
        user_id: user?.id?.toString() || '',
        club_name: ''
    });
    useEffect(() => {
        if (user) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log('User loaded:', user);
            }
            setReportData(prev => ({
                ...prev,
                user_id: user.id?.toString() || '',
                club_id: user.club_id?.toString() || '',
                admin: user.name || '',
            }));
        }
    }, [user]);
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        const sum = [
            reportData.computers_cash,
            reportData.computers_non_cash,
            reportData.computers_cloud_payments,
            reportData.sbp,
            reportData.ps_cash,
            reportData.ps_non_cash,
            reportData.bar_cash,
            reportData.bar_non_cash,
            reportData.cork_fee_cash,
            reportData.cork_fee_non_cash
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total: sum.toFixed(2) }));
    }, [
        reportData.computers_cash,
        reportData.computers_non_cash,
        reportData.computers_cloud_payments,
        reportData.sbp,
        reportData.ps_cash,
        reportData.ps_non_cash,
        reportData.bar_cash,
        reportData.bar_non_cash,
        reportData.cork_fee_cash,
        reportData.cork_fee_non_cash
    ]);
    useEffect(() => {
        const sumCash = [
            reportData.computers_cash,
            reportData.ps_cash,
            reportData.bar_cash,
            reportData.cork_fee_cash
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, cash: sumCash.toFixed(2) }));
    }, [
        reportData.computers_cash,
        reportData.ps_cash,
        reportData.bar_cash,
        reportData.cork_fee_cash
    ]);
    useEffect(() => {
        const sumNonCash = [
            reportData.computers_non_cash,
            reportData.ps_non_cash,
            reportData.bar_non_cash,
            reportData.cork_fee_non_cash,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, non_cash: sumNonCash.toFixed(2) }));
    }, [
        reportData.computers_non_cash,
        reportData.ps_non_cash,
        reportData.bar_non_cash,
        reportData.cork_fee_non_cash,
    ]);
    useEffect(() => {
        const sumCloudPayments = [
            reportData.computers_cloud_payments,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, cloud_payments: sumCloudPayments.toFixed(2) }));
    }, [
        reportData.computers_cloud_payments,
    ]);
    useEffect(() => {
        setReportData(prev => ({ ...prev, total: calculateTotal() }));
    }, [
        reportData.computers_cash,
        reportData.computers_non_cash,
        reportData.computers_cloud_payments,
        reportData.sbp,
        reportData.ps_cash,
        reportData.ps_non_cash,
        reportData.bar_cash,
        reportData.bar_non_cash,
        reportData.cork_fee_cash,
        reportData.cork_fee_non_cash
    ]);
    useEffect(() => {
        const sumPs = [
            reportData.ps_cash,
            reportData.ps_non_cash,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total_ps: sumPs.toFixed(2) }));
    }, [
        reportData.ps_cash,
        reportData.ps_non_cash,
    ]);
    useEffect(() => {
        const sumComputers = [
            reportData.computers_cash,
            reportData.computers_non_cash,
            reportData.computers_cloud_payments,
            reportData.sbp,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total_computers: sumComputers.toFixed(2) }));
    }, [
        reportData.computers_cash,
        reportData.computers_non_cash,
        reportData.computers_cloud_payments,
        reportData.sbp,
    ]);
    useEffect(() => {
        const sumBar = [
            reportData.bar_cash,
            reportData.bar_non_cash,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total_bar: sumBar.toFixed(2) }));
    }, [
        reportData.bar_cash,
        reportData.bar_non_cash,
    ]);
    useEffect(() => {
        const sumCorkFee = [
            reportData.cork_fee_cash,
            reportData.cork_fee_non_cash,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total_cork_fee: sumCorkFee.toFixed(2) }));
    }, [
        reportData.cork_fee_cash,
        reportData.cork_fee_non_cash,
    ]);
    useEffect(() => {
        const sumOnlinePayments = [
            reportData.sbp,
            reportData.cloud_payments,
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, online_payments: sumOnlinePayments.toFixed(2) }));
    }, [
        reportData.sbp,
        reportData.cloud_payments,
    ]);
    useEffect(() => {
        const sumtTotalInCheckout = [
            reportData.cash
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        setReportData(prev => ({ ...prev, total_checkout: sumtTotalInCheckout.toFixed(2) }));
    }, [
        reportData.cash
    ]);
    useEffect(() => {
        if (user) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log('User loaded:', user);
            }
            setReportData(prev => ({
                ...prev,
                user_id: user.id?.toString() || '',
                club_id: user.club_id?.toString() || '',
                club_name: user.clubName || '',
                admin: user.name || '',
            }));
            if (user.id) {
                fetchUserClubs(user.id.toString());
            }
        }
    }, [user]);
    useEffect(() => {
        if (process.env.DEBAG_MOD === 'DEBAG') { 
            console.log('Report Data:', reportData);  // Добавлено логирование для проверки данных отчета
            console.log('Clubs Data:', clubs);  // Логирование для проверки данных клубов
        }
    }, [reportData, clubs]);
    useEffect(() => {
        const cashAmount = parseFloat(reportData.cash) || 0;
        const encashmentAmount = parseFloat(reportData.encashment) || 0;
        const newTotalCheckout = initialTotalCheckout + cashAmount - encashmentAmount;
        setReportData(prev => ({ ...prev, total_checkout: newTotalCheckout.toFixed(2) }));
    }, [reportData.cash, reportData.encashment, initialTotalCheckout]);
    
    
    const calculateTotal = () => {
        const sum = [
            reportData.computers_cash,
            reportData.computers_non_cash,
            reportData.computers_cloud_payments,
            reportData.sbp,
            reportData.ps_cash,
            reportData.ps_non_cash,
            reportData.bar_cash,
            reportData.bar_non_cash,
            reportData.cork_fee_cash,
            reportData.cork_fee_non_cash
        ].reduce((acc, value) => acc + (parseFloat(value) || 0), 0);
        return sum.toFixed(2);
    };
    
    
    const fetchUserClubs = async (userId: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/getUserClubs/${userId}`);
            setClubs(response.data);
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log('Fetched clubs:', response.data);  // Добавлено логирование
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении клубов пользователя:', error);
            }
            if (axios.isAxiosError(error)) {
                console.error('Сервер статус:', error.response?.status);
                console.error('Дата ответа:', error.response?.data);
            } else {
                console.error('Неизвестная ошибка:', error);
            }
        }
    };
    
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const { name, value } = target;
        
        if (name === 'image_cheque') {
            const files = target.files;
            setReportData(prev => ({ ...prev, [name]: files ? files[0] : null }));
        } else {
            setReportData(prev => ({ ...prev, [name]: value }));
        }
    };
    
    
    const handleClubChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedClubId = event.target.value;
        if (process.env.DEBAG_MOD === 'DEBAG') {
            console.log("Selected Club ID:", selectedClubId);
        }
        
        const selectedClub = clubs.find(club => club.id.toString() === selectedClubId);
        if (selectedClub) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log("Selected Club:", selectedClub);
            }
            const selectedClubName = selectedClub.name;
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log("Selected Club Name:", selectedClubName);
            }
            
            setReportData(prev => ({
                ...prev,
                club_id: selectedClubId,
                club_name: selectedClubName,
            }));
        } else {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log("Ошибка выбранного клуба");
            }
        }
    };
    
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        
        const formData = new FormData();
        Object.entries(reportData).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                if (value instanceof File) {
                    formData.append('image_cheque', value);
                } else {
                    formData.append(key, String(value));
                }
            }
        });
        
        try {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log('Submitting report data:', reportData);  // Добавлено логирование
            }
            await axios.post(`${process.env.REACT_APP_API_URL}/api/report/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            window.location.reload();
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при отправке формы:', error);
            }
        }
    };
    
    
    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const fetchTotalCheckout = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/report/getLastReportCheckout`);
                if (response.data && response.data.totalCheckout !== undefined && isMounted) {
                    const initialTotal = parseFloat(response.data.totalCheckout);
                    setInitialTotalCheckout(initialTotal);
                    setReportData(prev => ({ ...prev, start_checkout: initialTotal.toFixed(2) }));
                } else {
                    throw new Error("Неверный ответ total_checkout");
                }
            } catch (error) {
                if (process.env.DEBAG_MOD === 'DEBAG') {
                    console.error('Ошибка при получении последнего отчета:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchTotalCheckout();
        return () => {
            isMounted = false;
        };
    }, []);
    
    
    return (
        <div className='submitReport_form_container'>
            {alert && <div className="alert alert-danger">{alert.message}</div>}
            {loading ? (
                <Spinner />
            ) : (
                <Form className='formContainer' onSubmit={handleSubmit} noValidate validated={validated}>
                    <Form.Group className="mb-3" controlId="admin">
                        <Form.Label>Администратор</Form.Label>
                        <Form.Control
                            type="text"
                            name="admin"
                            value={user?.name}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Администратор
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="club_id">
                        <Form.Label>Клуб</Form.Label>
                        <select
                            className="form-control"
                            name="club_id"
                            onChange={handleClubChange}
                            value={reportData.club_id}
                            required
                        >
                            <option value="">Выберите клуб</option>
                            {clubs.map(club => (
                                <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                        </select>
                        <Form.Control.Feedback type='invalid'>
                            Клуб
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="date">
                        <Form.Label>Дата</Form.Label>
                        <Form.Control
                            type="date"
                            name="date"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Дата
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="day_or_night">
                        <Form.Label>Смена</Form.Label>
                        <Form.Control 
                            as="select" 
                            name="day_or_night"
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Выберите смену</option>
                            <option value="День">День</option>
                            <option value="Ночь">Ночь</option>
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            Смена
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="image_cheque">
                        <Form.Label>Фотография чека</Form.Label>
                        <Form.Control
                            type="file"
                            name="image_cheque"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Фотография чека
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="comment">
                        <Form.Label>Комментарий</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            name="comment"
                            onChange={handleInputChange}
                            value={reportData.comment}
                        />
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group className="mb-3" controlId="computers_cash">
                        <Form.Label>ПК (Наличные)</Form.Label>
                        <Form.Control
                            type="text"
                            name="computers_cash"
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            ПК наличные
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="computers_non_cash"
                    >
                        <Form.Label>ПК (Безналичные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='computers_non_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            ПК (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="computers_cloud_payments"
                    >
                        <Form.Label>ПК (CloudPayments)</Form.Label>
                        <Form.Control
                            type='text'
                            name='computers_cloud_payments'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            ПК (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="ps_cash"
                    >
                        <Form.Label>PS (Наличные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='ps_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            PS (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="ps_non_cash"
                    >
                        <Form.Label>PS (Безналичные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='ps_non_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            PS (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="bar_cash"
                    >
                        <Form.Label>Бар (Наличные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='bar_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Бар (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="bar_non_cash"
                    >
                        <Form.Label>Бар (Безналичные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='bar_non_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Бар (Наличные)
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="cork_fee"
                    >
                        <Form.Label>Пробковый сбор (Наличные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='cork_fee_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Пробковый сбор
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="cork_fee"
                    >
                        <Form.Label>Пробковый сбор (Безналичные)</Form.Label>
                        <Form.Control
                            type='text'
                            name='cork_fee_non_cash'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Пробковый сбор
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="bonuses"
                    >
                        <Form.Label>Бонусы</Form.Label>
                        <Form.Control
                            type='text'
                            name='bonuses'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Бонусы
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="encashment"
                    >
                        <Form.Label>Инкассация</Form.Label>
                        <Form.Control
                            type='text'
                            name='encashment'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Инкассация
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="sbp"
                    >
                        <Form.Label>Итого ПК</Form.Label>
                        <Form.Control
                            type='text'
                            name='total_computers'
                            onChange={handleInputChange}
                            value={reportData.total_computers}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Итого ПК
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="non_cash"
                    >
                        <Form.Label>Итого PS</Form.Label>
                        <Form.Control
                            type='text'
                            name='total_ps'
                            onChange={handleInputChange}
                            value={reportData.total_ps}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Итого PS
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="cash"
                    >
                        <Form.Label>Итого Бар</Form.Label>
                        <Form.Control
                            type='text'
                            name='total_bar'
                            onChange={handleInputChange}
                            value={reportData.total_bar}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Итого Бар
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="sbp"
                    >
                        <Form.Label>Пробковый сбор</Form.Label>
                        <Form.Control
                            type='text'
                            name='total_cork_fee'
                            onChange={handleInputChange}
                            value={reportData.total_cork_fee}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Пробковый сбор
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="sbp"
                    >
                        <Form.Label>СБП</Form.Label>
                        <Form.Control
                            type='text'
                            name='sbp'
                            onChange={handleInputChange}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            СБП
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="non_cash"
                    >
                        <Form.Label>CloudPayments</Form.Label>
                        <Form.Control
                            type='text'
                            name='cloud_payments'
                            onChange={handleInputChange}
                            value={reportData.cloud_payments}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            CloudPayments
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="online_payments"
                    >
                        <Form.Label>Онлайн платежи</Form.Label>
                        <Form.Control
                            type='text'
                            name='online_payments'
                            onChange={handleInputChange}
                            value={reportData.online_payments}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Онлайн платежи
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="cash"
                    >
                        <Form.Label>Наличные</Form.Label>
                        <Form.Control
                            type='text'
                            name='cash'
                            onChange={handleInputChange}
                            value={reportData.cash}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Наличные
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="non_cash"
                    >
                        <Form.Label>Безналичные</Form.Label>
                        <Form.Control
                            type='text'
                            name='non_cash'
                            onChange={handleInputChange}
                            value={reportData.non_cash}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Безналичные
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <hr></hr>
                    
                    <Form.Group
                        className="mb-3"
                        controlId="total"
                    >
                        <Form.Label>Итого</Form.Label>
                        <Form.Control
                            type='text'
                            name='total'
                            onChange={handleInputChange}
                            value={reportData.total}
                            readOnly
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Итого
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="start_checkout">
                        <Form.Label>В кассе на начало смены</Form.Label>
                        <Form.Control
                            type="text"
                            name="start_checkout"
                            value={reportData.start_checkout}
                            readOnly
                            required
                        />
                        {loading ? <Spinner /> : null}
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="total_checkout">
                        <Form.Label>В кассе</Form.Label>
                        <Form.Control
                            type="text"
                            name="total_checkout"
                            value={reportData.total_checkout}
                            readOnly
                            required
                        />
                        {loading ? <Spinner /> : null}
                        <Form.Control.Feedback type='invalid'>
                            В кассе
                        </Form.Control.Feedback>
                    </Form.Group>
                    <button type="submit" className="modal_button">
                        ОТПРАВИТЬ
                    </button>
                </Form>
            )}
        </div>
    );
}

export default SubmitReport;