import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { IClub } from '../../interfaces/IClub';
import '../ModalStyles.css';

interface AddChatModalProps {
    show: boolean;
    onHide: () => void;
    onAddChat: (chatId: string, clubId: number, userName: string) => void;
    clubs: IClub[];
}

const AddChatModal: React.FC<AddChatModalProps> = ({ show, onHide, onAddChat, clubs }) => {
    const [chatId, setChatId] = useState('');
    const [clubId, setClubId] = useState<number | ''>('');
    const [userName, setUserName] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (chatId && clubId !== '' && userName.trim() !== '') {
            onAddChat(chatId, clubId as number, userName);
            setChatId('');
            setClubId('');
            setUserName('');
            onHide();
        } else {
            alert('Пожалуйста, введите идентификатор чата, имя пользователя и выберите клуб');
        }
    };
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Form className='modal_formContainer' onSubmit={handleSubmit}>
                    <Form.Group controlId="formChatId">
                        <Form.Label>ID телеграмм чата</Form.Label>
                        <Form.Control
                            type="text"
                            value={chatId}
                            onChange={(e) => setChatId(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formUserName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formClubId">
                        <Form.Label>Клуб</Form.Label>
                        <Form.Control
                            as="select"
                            value={clubId}
                            onChange={(e) => setClubId(parseInt(e.target.value))}
                            required
                        >
                            <option value="">Выберите клуб</option>
                            {clubs.map((club) => (
                                <option key={club.id} value={club.id}>
                                    {club.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <button className='modal_button' type="submit">
                        Добавить
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddChatModal;
