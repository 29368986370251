import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import '../ModalStyles.css';

interface AddClubHeadAdminModalProps {
    show: boolean;
    onHide: () => void;
    onAddClubHeadAdmin: (name: string, login: string, password: string, clubId: number) => void;
    clubs: { id: number, name: string }[];
}

const AddClubHeadAdminModal: React.FC<AddClubHeadAdminModalProps> = ({ show, onHide, onAddClubHeadAdmin, clubs }) => {
    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [clubId, setClubId] = useState<number | ''>('');
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (clubId !== '') {
            onAddClubHeadAdmin(name, login, password, clubId);
            setName('');
            setLogin('');
            setPassword('');
            setClubId('');
        }
    };
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Form className='modal_formContainer' onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </Form.Group>
                    
                    <Form.Group controlId="formLogin">
                        <Form.Label>Логин</Form.Label>
                        <Form.Control type="text" value={login} onChange={(e) => setLogin(e.target.value)} required />
                    </Form.Group>
                    
                    <Form.Group controlId="formPassword">
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </Form.Group>
                    
                    <Form.Group controlId="formClub">
                        <Form.Label>Клуб</Form.Label>
                        <Form.Control as="select" value={clubId} onChange={(e) => setClubId(parseInt(e.target.value))} required>
                            <option value="">Выберите клуб</option>
                            {clubs.map(club => (
                                <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>                
                    <button className="modal_button">
                        Добавить
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddClubHeadAdminModal;