import React, { useState, useEffect } from 'react';
import './HeadClubAdmin.css';
import '../../Styles.css';
import { Form } from 'react-bootstrap';
import { IClub } from '../../interfaces/IClub';
import { IUser } from '../../interfaces/IUser';
import AddClubAdminModal from '../../Modals/AddClubAdminModal/AddClubAdminModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext/AuthContext';



const HeadClubAdmin: React.FC = () => {
    const [clubs, setClubs] = useState<IClub[]>([]);
    const [showAddClubAdminModal, setShowAddClubAdminModal] = useState(false);
    const [selectedClubAdminId, setSelectedClubAdminId] = useState<number | ''>('');
    const [clubHeadAdmins, setClubHeadAdmins] = useState<IUser[]>([]);
    const [clubAdmins, setClubAdmins] = useState<IUser[]>([]);
    const [chatsId, setChatsId] = useState<{ tg_id: string }[]>([]);
    const navigate = useNavigate();
    const { user } = useAuth();
    
    useEffect(() => {
        if (user?.id) {
            fetchClubs(user.id);
            fetchClubHeadAdmins();
            fetchClubAdmins(user.id);
            fetchChats();
        }
    }, [user?.id]);
    
    const fetchClubs = async (userId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getUserClubs/${userId}`);
            const data = await response.json();
            setClubs(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении списка клубов:', error);
            }
        }
    };
    
    const fetchChats = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tgChats/getAll`);
            const data = await response.json();
            setChatsId(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении чатов:', error);
            }
        }
    };
    
    const fetchClubHeadAdmins = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-headClubAdmins`);
            const data: IUser[] = await response.json();
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.log('Club Head Admins:', data);
            }
            data.forEach((admin: IUser) => {
                if (process.env.DEBAG_MOD === 'DEBAG') {
                    console.log('Admin:', admin);
                    console.log('Admin Clubs:', admin.clubs);
                }
            });
            setClubHeadAdmins(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении списка руководителей клубов:', error);
            }
        }
    };
    
    const fetchClubAdmins = async (userId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAdminsByUserClubs/${userId}`);
            if (response.ok) {
                const data: IUser[] = await response.json();
                if (process.env.DEBAG_MOD === 'DEBAG') {
                    console.log('Club Admins:', data);
                }
                setClubAdmins(Array.isArray(data) ? data : []);
            } else {
                if (process.env.DEBAG_MOD === 'DEBAG') {
                    console.error('Ошибка при получении списка администраторов клубов:', await response.text());
                }
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении списка администраторов клубов:', error);
            }
        }
    };
    
    const handleAddClubAdmin = async (name: string, login: string, password: string, clubId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/add-clubAdmin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, login, password, clubId }),
            });
            
            if (response.ok && user?.id) {
                setShowAddClubAdminModal(false);
                fetchClubAdmins(user?.id);
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при добавлении администратора клуба');
            }
        } catch (error) {
            console.error('Ошибка при добавлении администратора клуба:', error);
        }
    };
    
    const handleDeleteClubAdmin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedClubAdminId === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/delete-clubAdmin/${selectedClubAdminId}`, {
                method: 'DELETE',
            });
            if (response.ok && user?.id) {
                setSelectedClubAdminId('');
                fetchClubAdmins(user?.id);
            } else {
                const data = await response.json();
                alert(data.message || 'Ошибка при удалении администратора клуба');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при удалении администратора клуба:', error);
            }
        }
    };
    
    
    
    return (
        <div className='headClubAdmin_container'>
            <div className='headClubAdmin_inputs'>
                <button className="modal_button" onClick={() => setShowAddClubAdminModal(true)}>
                    Добавить администратора компьютерного клуба
                </button>
                
                <AddClubAdminModal
                    show={showAddClubAdminModal}
                    onHide={() => setShowAddClubAdminModal(false)}
                    onAddClubHeadAdmin={handleAddClubAdmin}
                    clubs={clubs}
                />
                
                <Form noValidate className='formContainer' onSubmit={handleDeleteClubAdmin}>
                    <Form.Group className="mb-3" controlId="adminDelete">
                        <Form.Label>Удалить администратора компьютерного клуба</Form.Label>
                        <Form.Control as="select" value={selectedClubAdminId} onChange={(e) => setSelectedClubAdminId(parseInt(e.target.value))}>
                            <option value="">Выберите администратора</option>
                            {clubAdmins.map((admin) => {
                                const clubName = admin.clubs && admin.clubs.length > 0 ? admin.clubs[0].name : 'Без клуба';
                                return (
                                    <option key={admin.id} value={admin.id}>{admin.name} - {clubName}</option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="modal_button">Удалить администратора компьютерного клуба</button>
                </Form>
                
                <button className="onGenegateReport_button" onClick={() => navigate('/SubmitReport')}>
                    Страница сдачи отчета
                </button>
                
                <button className="onGenegateReport_button" onClick={() => navigate('/GenerateReport')}>
                    Страница формирования отчета
                </button>
            </div>
        </div>
    );
};

export default HeadClubAdmin;