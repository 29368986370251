import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Main from './Pages/Main/Main';
import GenerateReport from './Pages/GenerateReport/GenerateReport';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import HeadAdmin from './Pages/HeadAdmin/HeadAdmin';
import { AuthProvider } from './components/AuthContext/AuthContext';
import NavBar from './components/Navbar/Navbar';
import SubmitReport from './Pages/SubmitReport/SubmitReport';
import RegisterFirstUser from './Pages/RegisterFirstUser/RegisterFirstUser';
import HeadClubAdmin from './Pages/HeadClubAdmin/HeadClubAdmin';

const App: React.FC = () => {
    return (
        <div className='app_container'>
            <AuthProvider>
                <BrowserRouter>
                <nav>
                    <NavBar />
                </nav>
                    <Routes>
                        <Route path='*' element={<Navigate to='/' />} />
                        
                        <Route path='/' element={<Main />} />
                        
                        <Route path='/Reg' element={<RegisterFirstUser />} />
                        
                        <Route
                            path='/HeadAdmin'
                            element={
                                <ProtectedRoute requiredRoles={[1]}>
                                    <HeadAdmin />
                                </ProtectedRoute>
                            }
                        />
                        
                        <Route
                            path='/HeadClubAdmin'
                            element={
                                <ProtectedRoute requiredRoles={[2]}>
                                    <HeadClubAdmin />
                                </ProtectedRoute>
                            }
                        />
                        
                        <Route
                            path='/GenerateReport'
                            element={
                                <ProtectedRoute requiredRoles={[1, 2]}>
                                    <GenerateReport />
                                </ProtectedRoute>
                            }
                        />
                        
                        <Route
                            path='/SubmitReport'
                            element={
                                <ProtectedRoute requiredRoles={[1, 2, 3]}>
                                    <SubmitReport />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    );
};

export default App;