import React from 'react';
import { useAuth } from '../AuthContext/AuthContext';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

const NavBar: React.FC = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    
    const handleUserClick = () => {
        if (user) {
            if (user.role === 1) {
                navigate('/HeadAdmin');
            } else if (user.role === 3) {
                navigate('/SubmitReport');
            } else if (user.role === 2) {
                navigate('/HeadClubAdmin');
            }
        }
    };
    
    return (
        <div>
            {user && (
                <div className='nav_userContainer'>
                    <div className='nav_userInfo'>
                        <div className='nav_userName' onClick={handleUserClick}>
                            {user.name}
                        </div>
                        
                        <div className='nav_userName'>
                            {user.role === 1 ? 'Главный босс' : user.role === 2 ? 'Руководитель клуба' : 'Администратор клуба'}
                        </div>
                    </div>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16" onClick={logout}>
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default NavBar;