import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import '../ModalStyles.css';

interface RemoveClubFromUserModalProps {
    show: boolean;
    onHide: () => void;
    onRemoveClubFromUser: (userId: number, clubId: number) => void;
}

const RemoveClubFromUserModal: React.FC<RemoveClubFromUserModalProps> = ({ show, onHide, onRemoveClubFromUser }) => {
    const [users, setUsers] = useState<{ id: number, name: string }[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<number | ''>('');
    const [selectedClubId, setSelectedClubId] = useState<number | ''>('');
    const [clubs, setClubs] = useState<{ id: number, name: string }[]>([]);
    
    useEffect(() => {
        fetchUsers();
    }, []);
    
    const fetchUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-users`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении списка пользователей:', error);
            }
        }
    };
    
    const fetchUserClubs = async (userId: number) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${userId}/clubs`);
            const data = await response.json();
            setClubs(data);
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') {
                console.error('Ошибка при получении списка клубов пользователя:', error);
            }
        }
    };
    
    const handleUserChange = (userId: number) => {
        setSelectedUserId(userId);
        fetchUserClubs(userId);
    };
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedUserId !== '' && selectedClubId !== '') {
            onRemoveClubFromUser(parseInt(selectedUserId.toString()), parseInt(selectedClubId.toString()));
            setSelectedUserId('');
            setSelectedClubId('');
        }
    };
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Form className='modal_formContainer' onSubmit={handleSubmit}>
                    <Form.Group controlId="formUser">
                        <Form.Label>Пользователь</Form.Label>
                        <Form.Control as="select" value={selectedUserId} onChange={(e) => handleUserChange(parseInt(e.target.value))} required>
                            <option value="">Выберите пользователя</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formClub">
                        <Form.Label>Клуб</Form.Label>
                        <Form.Control as="select" value={selectedClubId} onChange={(e) => setSelectedClubId(parseInt(e.target.value))} required>
                            <option value="">Выберите клуб</option>
                            {clubs.map(club => (
                                <option key={club.id} value={club.id}>{club.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <button className='modal_button' type="submit">
                        Удалить
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RemoveClubFromUserModal;
