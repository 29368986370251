import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { IClub } from '../../interfaces/IClub';

interface IUser {
    id: number;
    name: string;
    role: number;
    token: string;
    clubName: string;
    club_id: string;
    clubs: IClub[];
}

interface AuthContextType {
    user: IUser | null;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            const parsedUser = JSON.parse(savedUser);
            return {
                ...parsedUser,
                id: parsedUser.user_id
            };
        }
        return null;
    });
    
    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);
    
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };
    
    const value = {
        user,
        setUser,
        logout,
    };
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth должен использоваться в AuthProvider');
    }
    return context;
};