import React, { useEffect, useState } from 'react';
import './GenerateReport.css';
import '../../Styles.css';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import formatDate from '../../logic/FormatDate';
import config from 'dotenv/config';

type SelectedCheckboxes = {
    date: boolean;
    day_or_night: boolean;
    comment: boolean;
    
    computers_cash: boolean;
    computers_non_cash: boolean;
    computers_cloud_payments: boolean;
    total_computers: boolean;
    
    ps_cash: boolean;
    ps_non_cash: boolean;
    total_ps: boolean;
    
    bar_cash: boolean;
    bar_non_cash: boolean;
    total_bar: boolean;
    
    total_cork_fee: boolean;
    cork_fee_cash: boolean;
    cork_fee_non_cash: boolean;
    
    bonuses: boolean;
    encashment: boolean;
    
    sbp: boolean;
    cloud_payments: boolean;
    online_payments: boolean;
    non_cash: boolean;
    cash: boolean;
    
    total: boolean;
    total_checkout: boolean;
};

const checkboxLabels = {
    date: 'Дата',
    day_or_night: 'День/Ночь',
    comment: 'Комментарий',
    
    computers_cash: 'ПК (Наличные)',
    computers_non_cash: 'ПК (Безналичые)',
    computers_cloud_payments: 'ПК CloudPayments',
    total_computers: 'ПК итого',
    
    ps_cash: 'PS (Наличные)',
    ps_non_cash: 'PS (Безналичые)',
    total_ps: 'PS итого',
    
    bar_cash: 'Бар (Наличные)',
    bar_non_cash: 'Бар (Безналичные)',
    total_bar: 'Бар итого',
    
    cork_fee_cash: 'Пробковый сбор (Наличные)',
    cork_fee_non_cash: 'Пробковый сбор (Безналичые)',
    total_cork_fee: 'Пробковый сбор (Итого)',
    
    bonuses: 'Бонусы',
    encashment: 'Инкассация',
    
    sbp: 'СБП',
    cloud_payments: 'CloudPayments',
    online_payments: 'Онлайн платежи',
    non_cash: 'Безналичные',
    cash: 'Наличные',
    
    total: 'Итого',
    total_checkout: 'В кассе',
};

const GenerateReport: React.FC = () => {
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [admin, setAdmin] = useState('');
    const [alert, setAlert] = useState<{ type: string; message: string } | null>(null);
    const [dayOrNightSelected, setDayOrNightSelected] = useState('');
    const [selectedClub, setSelectedClub] = useState('');
    const [clubs, setClubs] = useState<{ id: string; name: string }[]>([]);
    const [admins, setAdmins] = useState<{ id: string; name: string }[]>([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<SelectedCheckboxes>({
        date: false,
        day_or_night: false,
        comment: false,
        computers_cash: false,
        computers_non_cash: false,
        computers_cloud_payments: false,
        total_computers: false,
        ps_cash: false,
        ps_non_cash: false,
        total_ps: false,
        bar_cash: false,
        bar_non_cash: false,
        total_bar: false,
        cork_fee_cash: false,
        cork_fee_non_cash: false,
        total_cork_fee: false,
        bonuses: false,
        encashment: false,
        sbp: false,
        cloud_payments: false,
        online_payments: false,
        non_cash: false,
        cash: false,
        total: false,
        total_checkout: false,
    });
    
    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/getAll-users`);
                const data = await response.json();
                
                if (!response.ok) {
                    throw new Error(data.message || 'Не удалось загрузить список администраторов');
                }
                
                setAdmins(data);
            } catch (error) {
                if (error instanceof Error) {
                    setAlert({ type: 'danger', message: error.message });
                } else {
                    setAlert({ type: 'danger', message: 'Произошла неизвестная ошибка' });
                }
            }
        };
        
        fetchAdmins();
    }, []);
    
    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/club/getAll`);
                const data = await response.json();
                
                if (!response.ok) {
                    throw new Error(data.message || 'Не удалось загрузить список клубов');
                }
                
                setClubs(data);
            } catch (error) {
                if (error instanceof Error) {
                    setAlert({ type: 'danger', message: error.message });
                } else {
                    setAlert({ type: 'danger', message: 'Произошла неизвестная ошибка' });
                }
            }
        };
        
        fetchClubs();
    }, []);
    
    const handleClubChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const clubId = event.target.value;
        setSelectedClub(clubId);
        
        try {
            let url = `${process.env.REACT_APP_API_URL}/api/user/getAll-users`;
            if (clubId) {
                url = `${process.env.REACT_APP_API_URL}/api/user/getAdmins-byClub/${clubId}`;
            }
            const response = await fetch(url);
            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось загрузить список администраторов');
            }
            
            setAdmins(data.length ? data : []);
        } catch (error) {
            if (error instanceof Error) {
                setAlert({ type: 'danger', message: error.message });
            } else {
                setAlert({ type: 'danger', message: 'Произошла неизвестная ошибка' });
            }
        }
    };
    
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setSelectedCheckboxes(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };
    
    const handleDayOrNightSelectedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDayOrNightSelected(event.target.value);
    };
    
    const handleAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdmin(event.target.value);
    };
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        const formData = {
            admin: admin || null,
            dateRange: dateRange[0] && dateRange[1] ? dateRange : null,
            dayOrNightSelected: dayOrNightSelected || null,
            selectedCheckboxes,
            selectedClub: selectedClub || null
        };
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/report/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const blob = await response.blob();
            
            const downloadUrl = window.URL.createObjectURL(blob);
            
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `отчёт-${formatDate(new Date())}.xlsx`;
            document.body.appendChild(a);
            a.click();
            
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.log('Отчет успешно сгенерирован и скачан');
            }
        } catch (error) {
            if (process.env.DEBAG_MOD === 'DEBAG') { 
                console.error('Ошибка при отправке формы: ', error);
            }
        }
    };
    
    const toggleAllCheckboxes = () => {
        setSelectedCheckboxes(prevState => {
            const allChecked = Object.values(prevState).every(value => value);
            const newState: SelectedCheckboxes = { ...prevState };
            Object.keys(newState).forEach(key => {
                newState[key as keyof SelectedCheckboxes] = !allChecked;
            });
            return newState;
        });
    };
    
    return (
        <div className='generate_report_container'>
            <Form className='formContainer' noValidate onSubmit={handleSubmit}>
                <Form.Label>ПОЛЯ ОТЧЁТА </Form.Label>
                <Form.Group className="mb-3" controlId="admin">
                    <Form.Control 
                        as="select" 
                        name="admin" 
                        value={admin}
                        onChange={handleAdminChange}
                    >
                        <option value="">Администратор</option>
                        {admins.length > 0 ? (
                            admins.map(admin => (
                                <option key={admin.id} value={admin.id}>{admin.name}</option>
                            ))
                        ) : (
                            <option value="">Нет доступных администраторов</option>
                        )}
                    </Form.Control>
                    
                    <Form.Control.Feedback type='invalid'>
                        Администратор
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="club">
                    <Form.Control 
                        as="select" 
                        name="club" 
                        value={selectedClub}
                        onChange={handleClubChange}
                    >
                        <option value="">Клуб</option>
                        {clubs.map(club => (
                            <option key={club.id} value={club.id}>{club.name}</option>
                        ))}
                    </Form.Control>
                    
                    <Form.Control.Feedback type='invalid'>
                        Клуб
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="day_or_night">
                    <Form.Control 
                        as="select" 
                        name="day_or_night"
                        onChange={handleDayOrNightSelectedChange}
                    >
                        <option value="">День/Ночь</option>
                        <option value="День">День</option>
                        <option value="Ночь">Ночь</option>
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                        Смена
                    </Form.Control.Feedback>
                </Form.Group>
                
                <Form.Group controlId="dateRangePicker">
                    <div className='date_picker_container'>
                        <DatePicker
                            selectsRange={true}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            onChange={(update) => setDateRange(update as [Date | null, Date | null])}
                            isClearable={true}
                            className="form-control"
                            placeholderText='Выберите дату отчёта'
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                </Form.Group>
                
                <Form.Group className="mb-3" controlId="checkboxes">
                    <div className='checkboxses'>
                    {Object.keys(selectedCheckboxes).map((checkboxName) => (
                        <Form.Check
                            key={checkboxName}
                            type="checkbox"
                            name={checkboxName}
                            label={checkboxLabels[checkboxName as keyof typeof selectedCheckboxes]}
                            checked={selectedCheckboxes[checkboxName as keyof SelectedCheckboxes]}
                            onChange={handleCheckboxChange}
                        />
                    ))}
                    </div>
                </Form.Group>
                
                <div className='generate_report_formContainer_button_container'>
                    <button type="button" className="generateReport_button" onClick={toggleAllCheckboxes}>
                        Отметить все
                    </button>
                    
                    <button type="submit" className="generateReport_button">
                        Сформировать отчёт
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default GenerateReport;